body {
  background: url(https://www.fm618.net/img/bg-pattern.png),linear-gradient(to left,#7b4397,#dc2430);
  color: white;
}

.App {
  text-align: center;
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ul {
  list-style-type: none;
}

ul li {
  display: inline-block;
  margin: 2px 5px;
}

a {
  text-decoration: none;
  color: white;
}

a:active {
  text-decoration: underline;
}

.header-title {
  text-align: left;
}

.footer {
  position: fixed;
  bottom: 10px;
}