
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Lato/Lato.eot);
  src: local('Lato'), local('Lato Regular'), local('Lato-Regular'), url(../fonts/Lato/Lato.eot?#iefix) format('embedded-opentype'), url(../fonts/Lato/Lato.woff2) format('woff2'), url(../fonts/Lato/Lato.svg#Lato) format('svg'), url(../fonts/Lato/Lato.woff) format('woff'), url(../fonts/Lato/Lato.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local('Lato Italic'), local('Lato-Italic'), url(../fonts/Lato/Lato-Italic.woff2) format('woff2'), url(../fonts/Lato/Lato-Italic.woff) format('woff'), url(../fonts/Lato/Lato-Italic.ttf) format('truetype');
}

