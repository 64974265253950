
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 400;
  src: local('Muli Italic'), local('Muli-Italic'), url(../fonts/Muli/Muli-Italic.woff) format('woff'), url(../fonts/Muli/Muli-Italic.ttf) format('truetype'), url(../fonts/Muli/Muli-Italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Muli/Muli-Regular.eot);
  src: local('Muli Regular'), local('Muli-Regular'), local('Muli'), url(../fonts/Muli/Muli-Regular.woff) format('woff'), url(../fonts/Muli/Muli-Regular.eot?#iefix) format('embedded-opentype'), url(../fonts/Muli/Muli-Regular.ttf) format('truetype'), url(../fonts/Muli/Muli-Regular.woff2) format('woff2'), url(../fonts/Muli/Muli-Regular.svg#Muli) format('svg');
}



