@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 100;
  src: local('Catamaran Thin'), local('Catamaran-Thin'), url(../fonts/Catamaran/Catamaran-Thin.ttf) format('truetype'), url(../fonts/Catamaran/Catamaran-Thin.woff2) format('woff2'), url(../fonts/Catamaran/Catamaran-Thin.woff) format('woff');
}

@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 200;
  src: local('Catamaran ExtraLight'), local('Catamaran-ExtraLight'), url(../fonts/Catamaran/Catamaran-ExtraLight.ttf) format('truetype'), url(../fonts/Catamaran/Catamaran-ExtraLight.woff2) format('woff2'), url(../fonts/Catamaran/Catamaran-ExtraLight.woff) format('woff');
}

@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 300;
  src: local('Catamaran Light'), local('Catamaran-Light'), url(../fonts/Catamaran/Catamaran-Light.ttf) format('truetype'), url(../fonts/Catamaran/Catamaran-Light.woff2) format('woff2'), url(../fonts/Catamaran/Catamaran-Light.woff) format('woff');
}

@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Catamaran/Catamaran.eot);
  src: local('Catamaran'), local('Catamaran Regular'), local('Catamaran-Regular'), url(../fonts/Catamaran/Catamaran.eot?#iefix) format('embedded-opentype'), url(../fonts/Catamaran/Catamaran.ttf) format('truetype'), url(../fonts/Catamaran/Catamaran.svg#Catamaran) format('svg'), url(../fonts/Catamaran/Catamaran.woff2) format('woff2'), url(../fonts/Catamaran/Catamaran.woff) format('woff');
}

@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 500;
  src: local('Catamaran Medium'), local('Catamaran-Medium'), url(../fonts/Catamaran/Catamaran-Medium.ttf) format('truetype'), url(../fonts/Catamaran/Catamaran-Medium.woff2) format('woff2'), url(../fonts/Catamaran/Catamaran-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 600;
  src: local('Catamaran SemiBold'), local('Catamaran-SemiBold'), url(../fonts/Catamaran/Catamaran-SemiBold.ttf) format('truetype'), url(../fonts/Catamaran/Catamaran-SemiBold.woff2) format('woff2'), url(../fonts/Catamaran/Catamaran-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 700;
  src: local('Catamaran Bold'), local('Catamaran-Bold'), url(../fonts/Catamaran/Catamaran-Bold.ttf) format('truetype'), url(../fonts/Catamaran/Catamaran-Bold.woff2) format('woff2'), url(../fonts/Catamaran/Catamaran-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 800;
  src: local('Catamaran ExtraBold'), local('Catamaran-ExtraBold'), url(../fonts/Catamaran/Catamaran-ExtraBold.ttf) format('truetype'), url(../fonts/Catamaran/Catamaran-ExtraBold.woff2) format('woff2'), url(../fonts/Catamaran/Catamaran-ExtraBold.woff) format('woff');
}

@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 900;
  src: local('Catamaran Black'), local('Catamaran-Black'), url(../fonts/Catamaran/Catamaran-Black.ttf) format('truetype'), url(../fonts/Catamaran/Catamaran-Black.woff2) format('woff2'), url(../fonts/Catamaran/Catamaran-Black.woff) format('woff');
}

